
import {defineComponent} from "vue";
import EmployeeOverviewTable from "@/components/widgets/tables/Employees.vue";
import {Api} from "@/store/enums/StoreEnums";


export default defineComponent({
  name: "EmployeeOverview",
  components: {
    EmployeeOverviewTable,
  },
  setup() {
 
  },
  data () {
    return {
      dataApi: Api.GET_EMPLOYEE_IN_FACTORY,
      apiParams: {'factory_id': this.$route.params.idFactory}
    }   
  }

});
